import {tns} from "tiny-slider";

window.$ = window.jQuery = require('jquery');
require('popper.js');
require('bootstrap');
require('magnific-popup');

$(() => {
    "use strict";
    require('../../../../modules/system/assets/js/framework')
    require('../../../../modules/system/assets/js/framework.extras')

    $('.btn-email-head').on('click', function (e) {
        copyToClipboard($(this));
        alert('Email скопирован в буфер обмена!')
    });

    function copyToClipboard(element) {
        let $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(element).text()).select();
        document.execCommand("copy");
        $temp.remove();
    }

    // Masked input
    $("input[type='tel']").mask("+7 (999) 999-99-99");

    // Mobile menu behavior
    $("header .btn-burger").click(function() {
        $("header .mobile-menu").toggleClass("hidden");
    });

    // Smooth scroll
    $(".anchor-link").click(function() {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top-200+ "px"
        }, {
            duration: 1000,
            easing: "swing"
        });
        return false;
    });

    // Portfolio mobile category navigation
    $('.types-filter').click(function() {
        $('.types-filter-body').toggleClass('hidden');
    });
    $('.btn-backwards').click(function() {
        $('.types-filter-body').toggleClass('hidden');
    });

    // var lastWorks_slider = tns({
    //     container: '#lastWorks-slider',
    //     items: 1,
    //     controls: false,
    //     navContainer: "#lastWorks-thumbnails",
    //     navAsThumbnails: true,
    //     speed: 400,
    //     gutter: 10,
    //     autoplay: false,
    //     autoplayHoverPause: true,
    //     autoplayButtonOutput: false
    // });
    // var feedback_slider = tns({
    //     container: '#feedback-slider',
    //     items: 1,
    //     controls: true,
    //     speed: 400,
    //     gutter: 10,
    //     navPosition: "bottom",
    //     controlsText: ["<i class='fal fa-angle-left'></i>", "<i class='fal fa-angle-right'></i>"],
    //     autoplay: false,
    //     autoplayHoverPause: true,
    //     autoplayButtonOutput: false
    // });
    // var clients_slider_mobile = tns({
    //     container: '#clients-slider-mobile',
    //     items: 3,
    //     nav:true,
    //     controls:false,
    //     speed: 400,
    //     gutter: 30,
    //     navPosition: "bottom",
    // });

    $('.zoom-gallery').magnificPopup({
        delegate: 'a.gallery-link',
        type: 'image',
        closeOnContentClick: false,
        closeBtnInside: false,
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        image: {
            verticalFit: true,
        },
        gallery: {
            enabled: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>',
        },
        zoom: {
            enabled: true,
            duration: 300,
            opener: function(element) {
                return element.find('img');
            }
        }
    });
    $('.zoom-gallery-inner').magnificPopup({
        delegate: 'a',
        type: 'image',
        closeOnContentClick: false,
        closeBtnInside: false,
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        image: {
            verticalFit: true,
        },
        gallery: {
            enabled: false
        },
        zoom: {
            enabled: true,
            duration: 300,
            opener: function(element) {
                return element.find('img');
            }
        }
    });

    // Листинг услуг: уменьшаем шрифт, если количество символов больше заданного
    if($('*').is('.btn-category .lg-span')) {
        let label = $('section#offers .list .item .wrap .btn-category .lg-span');
        label.each(function(index) {
            if($(this).text().length >= 20) {
                $(this).css('font-size', '11px');
            }
        });
    }

    // Доп. услуги: уменьшаем шрифт, если количество символов больше заданного
    //main section#offers .list .item .wrap .btn-category span
    if($('*').is('section.additional-offers .list .item .wrap .btn-category span')) {
        let label = $('section.additional-offers .list .item .wrap .btn-category span');
        label.each(function(index) {
            if($(this).text().length >= 20) {
                $(this).css('font-size', '11px');
            }
        });
    }
});
